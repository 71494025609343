import React from 'react'

import all from 'src/images/calmax/all-in-one.svg'
import unite from 'src/images/calmax/calendars-unite.svg'
import plan from 'src/images/calendar/plan-anytime.svg'
import sideKick from 'src/images/calmax/sidekick.svg'
import habit from 'src/images/calmax/habit-haven.svg'
import rewards from 'src/images/calmax/rewards.svg'
import track from 'src/images/calmax/on-track.svg'
import allMobile from 'src/images/calmax/all-in-one-mobile.svg'
import uniteMobile from 'src/images/calmax/calendars-unite-mobile.svg'
import planMobile from 'src/images/calendar/plan-anytime-mobile.svg'
import sideKickMobile from 'src/images/calmax/sidekick-mobile.svg'
import rewardsMobile from 'src/images/calmax/rewards-mobile.svg'
import trackMobile from 'src/images/calmax/on-track-mobile.svg'
import { graphql, useStaticQuery } from 'gatsby'

import {
  Row,
  ContentContainer,
  Header,
  Title,
  Text,
  MobileImageContainer,
  TextContainer,
  MealImage,
} from './styles'

import { PlusBubble, BubbleText } from '../CalendarNewFeatures/styles'

import { CAL_MAX_FEATURES } from './constants'

const CalMaxFeatures = () => {
  const { meal } = useStaticQuery(graphql`
    query CalMaxFeaturesQuery {
      meal: file(relativePath: { eq: "calmax/meal.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const features = [
    { d: all, m: allMobile, color: '#A6C9DF' },
    { d: unite, m: uniteMobile, color: '#F5C67A' },
    { d: plan, m: planMobile, color: '#BDBDBD' },
    { d: sideKick, m: sideKickMobile, color: '#F3C6BC' },
    {
      d: habit,
      m: habit,
      color: '#7EB6B6',
    },
    {
      d: rewards,
      m: rewardsMobile,
      color: '#A6C9DF',
    },
    {
      d: meal,
      m: meal,
      color: '#DCB68C',
      png: true,
    },
    {
      d: track,
      m: trackMobile,
      color: '#F3C6BC',
    },
  ]

  return (
    <ContentContainer>
      <Header>Designed to Delight</Header>
      {CAL_MAX_FEATURES.map((feature, i) => (
        <Row key={i} color={features[i].color} reverse={i % 2 === 0}>
          {features[i].png ? (
            <MealImage
              image={features[i].d.childImageSharp.gatsbyImageData}
              alt={CAL_MAX_FEATURES[i].alt}
            />
          ) : (
            <>
              <MobileImageContainer
                key={features[i]}
                type="image/svg+xml"
                data={features[i].d}
                aria-label={CAL_MAX_FEATURES[i].alt}
              >
                svg-animation
              </MobileImageContainer>
              <MobileImageContainer
                key={features[i]}
                type="image/svg+xml"
                data={features[i].m}
                aria-label={`${CAL_MAX_FEATURES[i].alt}-mobile`}
              >
                svg-animation
              </MobileImageContainer>
            </>
          )}
          <TextContainer extra={i === 1}>
            {feature.plus && (
              <PlusBubble>
                <BubbleText>Calendar Plus</BubbleText>
              </PlusBubble>
            )}
            <Title>{feature.title}</Title>
            <Text>{feature.text}</Text>
          </TextContainer>
        </Row>
      ))}
    </ContentContainer>
  )
}

export default CalMaxFeatures
